.rsvp-container {
    display: flex; /* Change this to flex */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's on top */
    justify-content: center;
    align-items: center;
}

iframe {
    border: none;
    width: 80%;
    height: 80%;
    max-width: 800px;
    max-height: 600px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}