body {
    margin: 0;
    font-family: 'Serif', sans-serif;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

p {
    font-size: 1.5rem;
}

.cta {
    margin-top: 30px;
    display: inline-block;
    padding: 15px 20px;
    background-color: #0055b8;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta:hover {
    background-color: #003a75;
}

nav {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 0;
    text-align: center;
    color: whitesmoke;
}

nav a {
    color: #0780fa;
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s ease;
}

nav a:hover {
    color: #0780fa;
}
nav a:visited {
    color: whitesmoke;
}
.App {
    width: 100vw;
    height: 100vh;
    overflow: auto; /* To allow scrolling if content exceeds viewport height */
  }
/* Style for unvisited links */
a:link {
    text-decoration: none; /* Remove underline */
    color: white; /* Default color for unvisited links, you can change this if needed */
}

/* Style for visited links */
a:visited {
    font-weight: bold; /* Make the text bold */
    color: #000307; /* Change the color to match your theme, this is just an example */
}

/* Style for links when hovered */
a:hover {
    color: #003a75; /* Darker shade for hover effect */
}

/* Style for active links (when being clicked) */
a:active {
    color: #002244; /* Even darker shade for active state */
}
/* In your App.css or corresponding stylesheet */

.github-logo {
    width: 20px; /* adjust based on your design */
    margin-right: 10px; /* space between the logo and the Home link */
    vertical-align: middle; /* to align it with the text links */
  }

.discord-logo {
    width: 20px; /* adjust based on your design */
    margin-right: 10px; /* space between the logo and the Home link */
    vertical-align: middle; /* to align it with the text links */
}
.discord-banner-blurple {
    vertical-align: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}